<template>

    <drawer-form
            ref="drawerForm"
            :title="model.id > 0?'编辑':'添加'"
            :model="model"
            :rules="rules"
            :loading="loading"
            @handleSubmit="handleSubmit">

        <!--    <a-form-model-item label='图片' prop="logo">-->
        <!--      <multi-image-upload v-model="model.logo"></multi-image-upload>-->
        <!--    </a-form-model-item>-->

        <a-form-model-item label='标题' prop="title">
            <a-input v-model="model.title"/>
        </a-form-model-item>

        <!--    <a-form-model-item label='作者' prop="author">-->
        <!--      <a-input v-model="model.author"/>-->
        <!--    </a-form-model-item>-->

        <a-form-model-item label='内容' prop="content">
            <a-textarea v-model="model.content" style="min-height: 120px"></a-textarea>
        </a-form-model-item>

        <a-form-model-item label='图片或视频' prop="title">
            <multi-image-upload ref="multiUpload" v-model="model.res"></multi-image-upload>
        </a-form-model-item>


    </drawer-form>

</template>

<script>

    export default {
        data() {
            return {
                isShow: false,
                model: {
                    id: null,
                    title: null,
                    logo: null,
                    author: null,
                    tag: null,
                    content: "",
                    res: ""
                },
                rules: {},
                loading: false,

            }
        },
        methods: {
            show(model = {content: ""}) {
                this.model = JSON.parse(JSON.stringify(model))
                if(this.model.res){
                    this.model.res = JSON.parse(this.model.res)
                    setTimeout(() => {
                        this.$refs.multiUpload.setValue(this.model.res)
                    }, 500)
                }

                this.$refs.drawerForm.show()
            },
            hide() {
                this.$refs.drawerForm.hide()
            },

            handleSubmit() {
                if (this.model.id > 0) {
                    this.edit()
                } else {
                    this.add()
                }
            },

            add() {
                this.loading = true
                this.$post('web/app-forum', {
                    ...this.model,
                    res:JSON.stringify(this.model.res)
                }).then(r => {
                    this.loading = false
                    this.$emit('success')
                    this.hide()
                }).catch(err => {
                    this.loading = false
                })
            },
            edit() {
                this.loading = true
                this.$put('web/app-forum', {
                    ...this.model,
                    res:JSON.stringify(this.model.res)
                }).then(r => {
                    this.loading = false
                    this.$emit('success')
                    this.hide()
                }).catch(err => {
                    this.loading = false
                })
            }

        },

    }
</script>

<style>

</style>
